@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (max-width: 768px) {
  .modal-mobile .mantine-Modal-inner{
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
  }
  .modal-mobile .mantine-Modal-body{
    padding-right: 5px;
    padding-left: 5px;
  }

  .modal-mobile-content{
    padding-left: 5px;
    padding-right: 5px;
  }
  .Table-leagues {
    padding-right: 6px !important;
    padding-left: 6px !important;
}
.Table-leagues th {
  font-size: 12px;
}
.Table-leagues td {
  font-size: 13px;
}
.line-height-text{
  line-height: 1.5em !important;
}
.tete-match {
  flex-flow: nowrap !important;
}
.tete-match p {
  width: 33%;
}
.ladder-mobile{
  display: flex;
    flex-direction: column;
}
.center-mobile{
  display: flex
  ;
      justify-content: center;
}
}